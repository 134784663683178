<template>
    <div class="view-role">
        <el-form
            ref="roleForm"
            :inline="true"
            :model="form"
            class="form-inline"
            label-width="80px"
            size="mini"
            label-position="left"
        >
            <el-row>
                <el-form-item label="角色标识" prop="role">
                    <el-input
                        v-model="form.role"
                        placeholder="角色标识"
                    ></el-input>
                </el-form-item>
                <el-form-item label="角色名称" prop="role_name">
                    <el-input
                        v-model="form.role_name"
                        placeholder="角色名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryTable"
                        >查询</el-button
                    >
                    <el-button
                        @click="resetForm"
                        icon="el-icon-refresh-right"
                    ></el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <div class="button-ctrl">
            <el-button
                type="success"
                @click="showRoleDialog('add')"
                icon="el-icon-plus"
                size="mini"
                >新增</el-button
            >
        </div>
        <TableList
            class="role-table"
            ref="roleTable"
            :query="form"
            :columns="table.columms"
            :tableData.sync="table.tableData"
            :page.sync="table.page"
            :doHandler="table.doHandler"
            fullHeight
            border
        >
            <template slot="action" slot-scope="{ data }">
                <div class="column-scope-action">
                    <el-link
                        type="primary"
                        icon="el-icon-edit"
                        @click="showRoleDialog('update', data)"
                        >编辑</el-link
                    >
                    <el-link
                        type="primary"
                        icon="el-icon-connection"
                        @click="showRelMenuDrawer(data)"
                        >绑定菜单</el-link
                    >
                    <el-link
                        type="info"
                        icon="el-icon-view"
                        @click="showRoleDialog('detail', data)"
                        >详情</el-link
                    >
                    <el-link
                        type="danger"
                        icon="el-icon-delete"
                        @click="deleteRole(data)"
                        >删除</el-link
                    >
                </div>
            </template>
        </TableList>
        <RoleDialog
            :title="dialog.roleInfo.title"
            :visible.sync="dialog.roleInfo.visible"
            :type="dialog.roleInfo.type"
            :data="dialog.roleInfo.data"
            @reload="queryTable"
        />
        <RoleMenu
            :visible.sync="drawer.roleMenu.visible"
            :data="drawer.roleMenu.data"
        />
    </div>
</template>
<script>
import TableList from "@/components/table-list";
import RoleDialog from "./roleDialog.vue";
import RoleMenu from "./roleMenu.vue";
import { getRolePageApi, deleteRoleApi } from "@/api/system/role.js";
import columms from "./columns";

export default {
    name: "Role",
    components: {
        TableList,
        RoleDialog,
        RoleMenu
    },
    data() {
        return {
            form: {
                role: "",
                role_name: ""
            },
            table: {
                columms,
                page: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                doHandler: getRolePageApi,
                tableData: []
            },
            dialog: {
                roleInfo: {
                    visible: false,
                    type: "",
                    title: "",
                    data: undefined
                }
            },
            drawer: {
                roleMenu: {
                    visible: false,
                    data: undefined
                }
            }
        };
    },
    methods: {
        queryTable() {
            this.$refs["roleTable"].doQuery();
        },
        resetForm() {
            this.$refs["roleForm"].resetFields();
        },
        showRoleDialog(type, data) {
            this.dialog.roleInfo.visible = true;
            this.dialog.roleInfo.type = type;
            this.dialog.roleInfo.data = data;
            this.dialog.roleInfo.title =
                type === "add"
                    ? "新增角色"
                    : type === "update"
                    ? "编辑角色"
                    : "角色详情";
        },
        showRelMenuDrawer(data) {
            this.drawer.roleMenu.visible = true;
            this.drawer.roleMenu.data = data;
        },
        deleteRole(data) {
            this.$confirm(
                `此操作将永久删除该角色（角色名称：${data.role_name}）, 是否继续?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.callDeleteRoleApi(data);
                })
                .catch(() => {})
                .finally(() => {});
        },
        async callDeleteRoleApi(data) {
            return new Promise((resolve, reject) => {
                deleteRoleApi(data)
                    .then(() => {
                        this.$notify({
                            title: "成功",
                            message: "删除成功",
                            type: "success"
                        });
                        resolve();
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    })
                    .finally(() => {
                        this.queryTable();
                    });
            });
        }
    },
    mounted() {
        this.queryTable();
    }
};
</script>
<style lang="scss" scoped>
.view-role {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    .button-ctrl {
        margin-bottom: 10px;
    }
    .role-table {
        flex: 1;
    }
    .column-scope-action {
        > * + * {
            margin-left: 10px;
        }
    }
}
</style>
