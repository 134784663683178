export default [
    {
        prop: "role",
        label: "角色标识",
        minWidth: 160
    },
    {
        prop: "role_name",
        label: "角色名称",
        minWidth: 255
    },
    {
        prop: "action",
        label: "操作",
        fixed: "right",
        width: 270
    }
];
